import { ImageList, ImageListItem } from '@mui/material';
import * as Carousel from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import empty from './empty-1.jpg';
import { GET_URL, MAVINTERNATIONAL } from '../../utils/const';
const ImageComp = (props: any) => {
  const { imageList, year, album } = props;
  const imageStyle = {
    width:'10em',
    height:'10em'
  }
  const divStyle = {
    display:'flex',
    alignItems: 'center',
    
  }
  return (
    <div>
      <div style={{...divStyle,flexDirection:'column'}}>
      {imageList.length <= 0 &&
        <>

          <h2 style={{ textAlign: 'center', fontFamily:'sans-serif' }}>
            Select year & gallery to show images
          </h2>
          <img src={require('./empty-1.jpg')} style={imageStyle}/>
        </>
      }
</div>
      <Carousel.Carousel showArrows={true} dynamicHeight={false}>
        {imageList.map((item: any, index: any) => (

          <div id={index}>
            <img
              src={`${GET_URL(MAVINTERNATIONAL)}${album}${item}`}
              key={index}
            />
          </div>


        ))}
      </Carousel.Carousel>

    </div>
  )
}
export default ImageComp;
